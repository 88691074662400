import CardMajor from "./card.major";
import PropTypes from "prop-types";

const CardListMajor = ({ entries, index }) => (
  <div className="o-card-listing">
    {entries.map(entry => (
      <CardMajor entry={entry} key={entry.title} index={index} />
    ))}
  </div>
);

CardListMajor.propTypes = {
  entries: PropTypes.array,
  index: PropTypes.number,
};

export default CardListMajor;
