import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import { prettyDate } from "../../helpers/dates";
import Image from "../media/image";
import PropTypes from "prop-types";

const CardMajor = ({ entry, index, tag }) => (
  <RenLink href={entry} classes="c-card-major--fullheight o-card-listing__item">
    {entry.image && entry.image.asset && (
      <Image
        className="c-card-major--image"
        image={entry.image}
        interceptBuilder={builder => builder.width(450)}
        alt={entry.image.alt}
      />
    )}
    <div
      className={
        entry.image
          ? "c-card-major u-text--black"
          : "c-card-major c-card-major--noimage u-text--black"
      }
    >
      <div>
        <div className="c-card-major__info--margin">
          <div className="c-card-major__top-bar">
            {tag && <p className="c-card-major__top-bar-tag">{tag}</p>}
            {entry.byline.publishDate && (
              <p className="c-card-major__top-bar-date u-text--grey">
                {prettyDate({ date: entry.byline.publishDate })}
              </p>
            )}
          </div>
        </div>
        <div className="c-card-major__info--margin">
          <h3>{entry.title}</h3>
        </div>
      </div>
      <div className="c-card-major__arrow">
        <ArrowIcon />
      </div>
    </div>
  </RenLink>
);

CardMajor.propTypes = {
  entry: PropTypes.object,
  index: PropTypes.number,
  tag: PropTypes.string,
};

export default CardMajor;
