import CardLink from "./card.link";
import CardLinkTwoIllus from "./card.link.two.illus";
import PropTypes from "prop-types";

const CardListLink = ({ entries = [], type = "" }) => {
  return (
    <div className="">
      <div className="o-card-listing">
        {!entries.find(({ _type = "" }) =>
          ["single", "scienceArticleFrontPage", "schoolAndEducation"].includes(
            _type
          )
        )
          ? entries.map(entry => (
              <CardLink entry={entry} key={entry.title} type={type} />
            ))
          : entries.map(entry => (
              <CardLinkTwoIllus entry={entry} key={entry.title} />
            ))}
      </div>
    </div>
  );
};

CardListLink.propTypes = {
  entries: PropTypes.array,
};

export default CardListLink;
