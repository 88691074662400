import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import Image from "../media/image";
import PropTypes from "prop-types";

const CardLink = ({ entry, entry: { title, frontPageIllustrations } }) => {
  return (
    <RenLink href={entry} classes="o-card-listing__item c-card-link--listing">
      <div className="c-card-link-two-illus u-bg--white">
        <div className="c-card-link-two-illus__illu-spacer">
          {/* Item 1 */}
          <div className="c-card-link-two-illus__illu-item">
            {title && <h3>{title}</h3>}
            {frontPageIllustrations && frontPageIllustrations[1] && (
              <Image
                image={frontPageIllustrations[1]}
                alt={frontPageIllustrations[1].alt}
              />
            )}
          </div>

          {/* Item 2 */}
          <div className="c-card-link-two-illus__illu-item">
            {frontPageIllustrations && frontPageIllustrations[0] && (
              <Image
                image={frontPageIllustrations[0]}
                alt={frontPageIllustrations[0].alt}
              />
            )}
          </div>
        </div>
        <div className="c-card-link-two-illus__arrow">
          <ArrowIcon link="/" />
        </div>
      </div>
    </RenLink>
  );
};

CardLink.propTypes = {
  entry: PropTypes.object,
  title: PropTypes.string,
  illustration: PropTypes.object,
};

export default CardLink;
