import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import PropTypes from "prop-types";
import {
  resolveSanityUrl,
  resolveSanityUrlFilepath,
} from "../../helpers/resolveSanityUrl";

const RenbladCardLink = ({
  entry,
  entry: { title, lede, seriesNumber },
  index,
}) => {
  return (
    <RenLink
      href={entry}
      classes={
        `u-text--black c-renblad-card o-card-listing__item` +
        (index % 2 != 0 ? " u-bg--lightgrey" : " u-bg--white")
      }
    >
      <div className="c-renblad-card--spacing">
        <div className="c-renblad-card__content">
          {seriesNumber && (
            <p className="c-renblad-card__tag">{seriesNumber}</p>
          )}
          {title && <h3 className="u-text--darkGreen u-roboto500">{title}</h3>}
          {lede && <p className="c-renblad-card__lede">{lede}</p>}
        </div>
        {resolveSanityUrl(entry) && (
          <div className="c-renblad-card__arrow">
            <ArrowIcon />
          </div>
        )}
      </div>
    </RenLink>
  );
};

RenbladCardLink.propTypes = {
  entry: PropTypes.object,
  title: PropTypes.string,
  lede: PropTypes.string,
  seriesNumber: PropTypes.string,
};

export default RenbladCardLink;
