import RenLink from "./RenLink";
import { prettyDate } from "../../helpers/dates";
import ArrowIcon from "../icons/arrow.icon";
import Image from "../media/image";
import PropTypes from "prop-types";

const ArticleMosaic = ({ entries }) => (
  <div className="c-article-mosaic">
    {entries.map((entry, index) => (
      <ArticleMosaicItem entry={entry} key={index} />
    ))}
  </div>
);

const ArticleMosaicItem = ({ entry }) => (
  <RenLink href={entry} classes="c-article-mosaic-item">
    {entry.image && entry.image.asset && (
      <Image
        className="c-article-mosaic-item__image"
        image={entry.image}
        alt={""}
        interceptBuilder={builder => builder.width(600).height(400)}
      />
    )}
    <div className="c-article-mosaic-item__content">
      <div className="c-article-mosaic-item--ie11">
        {entry.byline && entry.byline.publishDate && (
          <p className="c-article-mosaic-item__datetag">
            {prettyDate({ date: entry.byline.publishDate })}
          </p>
        )}
        <h3 className="c-article-mosaic-item__title">{entry.title}</h3>
        <p className="c-article-mosaic-item__lede">{entry.lede}</p>
        {!(entry.image && entry.image.asset) && <p>{entry.lede}</p>}
      </div>
      <div className="c-article-mosaic-item__arrow">
        <ArrowIcon />
      </div>
    </div>
  </RenLink>
);

ArticleMosaic.propTypes = {
  entries: PropTypes.array,
  index: PropTypes.number,
};
ArticleMosaicItem.propTypes = {
  entry: PropTypes.object,
};

export default ArticleMosaic;
