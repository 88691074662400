import CardFull from "./card.full";
import PropTypes from "prop-types";

const CardListFull = ({ entries, index }) => (
  <div className="c-card-full--listing">
    {entries.map(entry => (
      <CardFull entry={entry} key={entry.title} index={index} />
    ))}
  </div>
);

CardListFull.propTypes = {
  entries: PropTypes.array,
  index: PropTypes.number,
};

export default CardListFull;
