import ArrowIcon from "../icons/arrow.icon";
import Image from "../media/image";
import RenLink from "./RenLink";
import PropTypes from "prop-types";

const CallToAction = ({ action: { title, description, image, link } }) => {
  return (
    <div className="u-bg--lightgrey u-padding-top-bottom--large">
      <div className="c-call-action">
        <div className="c-call-action__body">
          <div>
            <h3 className="u-text--darkGreen u-text--bold">{title}</h3>
            <p>{description}</p>
          </div>
          {link && link.url && (
            <RenLink href={link.url}>
              <div className="c-call-action__contact">
                <ArrowIcon />
                <p className="c-call-action__contact-text">
                  {link.text || "Se mer"}
                </p>
              </div>
            </RenLink>
          )}
          {link && !link.url && (
            <RenLink href={link.internalPage}>
              <div className="c-call-action__contact">
                <ArrowIcon />
                <p className="c-call-action__contact-text">
                  {link.text || "Se mer"}
                </p>
              </div>
            </RenLink>
          )}
        </div>
        {image && (
          <Image
            image={image}
            interceptBuilder={builder => builder.width(600)}
            className="c-call-action__image"
          />
        )}
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  action: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
};

export default CallToAction;
